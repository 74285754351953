import React, { FunctionComponent } from 'react'
/** @jsx jsx */
import { Box, jsx, Flex } from 'theme-ui'
import { css, Global } from '@emotion/core'
import { Link as GatsbyLink } from 'gatsby'

import { slide as MobileMenu } from 'react-burger-menu'

import { mq } from '../../theme'
import mobileMenuStyles from './mobileMenuStyles'
import Logo from '../Logo'

type LinkProps = {
  to: string
  title?: string
  first?: boolean
  last?: boolean
  mode?: 'overlay' | 'light'
}

const Link: FunctionComponent<LinkProps> = ({
  first,
  last,
  mode = 'dark',
  ...props
}) => (
  <GatsbyLink
    sx={{
      cursor: 'pointer',
      textDecoration: 'none',
      color: mode === 'overlay' ? 'white' : 'blue.2',
      fontSize: [2],
      [first ? 'mr' : last ? 'ml' : 'mx']: [2],
      py: [2],
      transition: '.5s all ease',
      borderBottom: '1px solid transparent',
      '&:hover': {
        borderBottom: '1px solid',
      },
    }}
    {...props}
  />
)

const MobileLink: FunctionComponent<LinkProps> = ({
  first,
  last,
  ...props
}) => (
  <GatsbyLink
    sx={{
      display: 'block',
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'white',
      fontSize: [2],
      py: [2],
      outline: 0,
    }}
    {...props}
  />
)

type HeaderProps = { mode?: 'overlay' | 'light' }

export const Header: FunctionComponent<HeaderProps> = ({ mode = 'light' }) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          .bm-burger-button {
            ${mq.sm} {
              display: none;
            }
            .bm-burger-bars {
              background: ${mode === 'overlay'
                ? 'white'
                : '#373a47'} !important;
            }
          }
        `}
      />
      <Box
        sx={{
          position: mode === 'overlay' ? 'absolute' : 'initial',
          zIndex: '1',
          width: '100%',
        }}
        bg={mode === 'overlay' ? 'rgba(0, 0, 0, 0.68)' : 'white'}
      >
        <nav role="navigation" aria-label="main-navigation">
          <Box variant="container">
            <Flex
              sx={{
                height: ['80px', '103px'],
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ maxWidth: ['183px'] }}>
                <GatsbyLink to="/" title="Logo">
                  <Logo fill={mode === 'overlay' ? 'white' : '#365a85'} />
                </GatsbyLink>
              </Box>
              <Box sx={{ display: ['none', 'block'] }}>
                <Link mode={mode} to="/">
                  Home
                </Link>
                <Link mode={mode} to="/about">
                  About
                </Link>
                <Link mode={mode} to="/services">
                  Services
                </Link>
                <Link mode={mode} to="/articles">
                  Articles
                </Link>
                <Link mode={mode} to="/contact" last>
                  Contact
                </Link>
              </Box>
            </Flex>
          </Box>
        </nav>
      </Box>
      <MobileMenu styles={mobileMenuStyles} right>
        <MobileLink to="/">Home</MobileLink>
        <MobileLink to="/about">About</MobileLink>
        <MobileLink to="/services">Services</MobileLink>
        <MobileLink to="/articles">Articles</MobileLink>
        <MobileLink to="/contact">Contact</MobileLink>
      </MobileMenu>
    </React.Fragment>
  )
}
