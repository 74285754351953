import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import { withPrefix } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import Header from './Header'
import { Box, Styled } from 'theme-ui'
import Footer from './Footer'
import Container from './Container'
import { useStaticQuery, graphql } from 'gatsby'

type LayoutProps = {
  header: {
    title: string
    openingContent?: string
    image?: {
      childImageSharp: {
        fluid: FluidObject
      }
      publicURL?: string
    }
    boxFeaturedImage?: boolean
  }
  footer: {
    showCTA: boolean
  }
  seo: {
    title: string
    description: string
  }
  menuOverlay?: boolean
}

const TemplateWrapper: FunctionComponent<LayoutProps> = ({
  header,
  footer,
  seo,
  menuOverlay,
  children,
}) => {
  const siteData = useStaticQuery(graphql`
    query Site {
      site {
        siteMetadata {
          title
          description
          siteURL
        }
      }
    }
  `)

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/favicon/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta
          property="og:title"
          content={seo.title || siteData.site.siteMetadata.title}
        />
        <meta
          property="og:description"
          content={seo.description || siteData.site.siteMetadata.description}
        />
        <meta property="og:type" content="business.business" />

        <meta
          property="og:image"
          content={
            header.image?.publicURL
              ? siteData.site.siteMetadata.siteURL + header.image?.publicURL
              : withPrefix('/') + 'img/social-img.jpg'
          }
        />

        <title>
          {seo.title} | {siteData.site.siteMetadata.title}
        </title>
        <meta
          name="description"
          content={seo.description || siteData.site.siteMetadata.description}
        />
      </Helmet>

      <Header mode={menuOverlay ? 'overlay' : 'light'} />
      <Box
        variant={header.boxFeaturedImage ? 'container' : ''}
        sx={{ mt: header.boxFeaturedImage ? 4 : '' }}
      >
        {header.image && (
          <Img fluid={header.image.childImageSharp.fluid} alt="" />
        )}
      </Box>
      <Container
        bg={header.image ? 'white' : 'blue.0'}
        extraSx={{
          textAlign: header.boxFeaturedImage ? 'left' : 'center',
        }}
      >
        <Styled.h1>{header.title}</Styled.h1>
        <Styled.p>{header.openingContent}</Styled.p>
      </Container>
      <div>{children}</div>
      <Footer withCTA={footer.showCTA} />
    </div>
  )
}

export default TemplateWrapper
