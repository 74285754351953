import React, { FunctionComponent } from 'react'
import { Box } from 'theme-ui'

interface ContainerProps {
  bg: string
  extraSx?: {}
}

export const Container: FunctionComponent<ContainerProps> = ({
  children,
  bg,
  extraSx = {},
  ...props
}) => (
  <Box sx={{ bg, py: [4, 5], ...extraSx }} {...props}>
    <Box variant="container">{children}</Box>
  </Box>
)
