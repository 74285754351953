import React, { FunctionComponent } from 'react'
/** @jsx jsx */
import { Box, Styled, jsx, Button } from 'theme-ui'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Link } from 'gatsby'

import AccountingLogos from './AccountingLogos'

type FooterProps = {
  withCTA?: boolean
}

export const Footer: FunctionComponent<FooterProps> = ({ withCTA: cta }) => {
  return (
    <React.Fragment>
      {cta && (
        <Box p="5">
          <Box
            variant="container"
            sx={{
              textAlign: 'center',
              color: 'blue.2',
              h2: { fontSize: [5, 6] },
            }}
          >
            <Styled.h2>Want to know more?</Styled.h2>
            <Styled.p sx={{ mb: 4 }}>
              For a free no obligation assessment of your needs and how we can
              help, please get in touch
            </Styled.p>
            <Button as={Link} variant="outline" to="/contact">
              Contact Us
            </Button>
          </Box>
        </Box>
      )}
      <AccountingLogos />
      <Box bg="blue.1">
        <Box variant="container" p="5" sx={{ textAlign: 'center' }}>
          <Styled.p
            sx={{
              display: 'block',
              maxWidth: '620px',
              color: 'white',
              margin: '0 auto',
            }}
          >
            Westgate House, Westgate Ave, Bolton, BL1 4RF <br />
            <OutboundLink
              eventAction="tap"
              eventCategory="call_clicks"
              eventLabel="footer_call"
              href="tel:01204389006"
              sx={{ color: 'white', textDecoration: 'none' }}
            >
              01204 389006
            </OutboundLink>{' '}
            <span sx={{ display: ['none', 'inline-block'] }}>| </span>
            <OutboundLink
              eventAction="tap"
              eventCategory="email_clicks"
              eventLabel="footer_email"
              href="mailto:office@accountablebookkeeping.co.uk"
              sx={{ color: 'white', textDecoration: 'none' }}
            >
              office@accountablebookkeeping.co.uk
            </OutboundLink>{' '}
          </Styled.p>
          <Styled.p
            sx={{
              fontSize: '14px !important',
              fontWeight: 300,
              color: 'white',
            }}
          >
            Accountable Bookkeeping LTD is a registered company in England,
            Scotland and Wales. Company number: 06676459
          </Styled.p>
        </Box>
      </Box>
    </React.Fragment>
  )
}
