import React, { FunctionComponent } from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'

type LogoProps = {
  fill?: string
}

const Logo: FunctionComponent<LogoProps> = ({ fill = '#365a85' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 181.52 46.6"
      sx={{ width: '100%' }}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g
            id="ACCOUNTABLE_BOOKKEEPING"
            data-name="ACCOUNTABLE BOOKKEEPING"
            style={{ isolation: 'isolate' }}
          >
            <g style={{ isolation: 'isolate' }}>
              <path
                d="M2.1,18.15H0L7.7.45H9.45l7.7,17.7h-2.1l-2-4.65h-9Zm2.63-6.3H12.4L8.58,2.55Z"
                style={{ fill: fill }}
              />
              <path
                d="M34.12,15.35A6.57,6.57,0,0,1,33,16.62a6.52,6.52,0,0,1-1.51,1,8.42,8.42,0,0,1-1.84.69,8.31,8.31,0,0,1-2.09.25,9.43,9.43,0,0,1-3.66-.7A8.84,8.84,0,0,1,19.05,13a9.68,9.68,0,0,1-.7-3.73,9.59,9.59,0,0,1,.7-3.72A8.76,8.76,0,0,1,21,2.64,8.88,8.88,0,0,1,23.91.7,9.43,9.43,0,0,1,27.57,0a9.11,9.11,0,0,1,3.31.65A6.59,6.59,0,0,1,33.7,2.8L32.12,4a4.36,4.36,0,0,0-.74-.86,5.35,5.35,0,0,0-1.09-.74A6.53,6.53,0,0,0,29,1.85a5.28,5.28,0,0,0-1.41-.2,7.14,7.14,0,0,0-3.05.63A6.74,6.74,0,0,0,22.24,4,7.2,7.2,0,0,0,20.8,6.39a8.72,8.72,0,0,0,0,5.82,7.2,7.2,0,0,0,1.44,2.44,6.9,6.9,0,0,0,2.28,1.68,7.29,7.29,0,0,0,3.05.62A8.07,8.07,0,0,0,29,16.83a6.06,6.06,0,0,0,1.35-.43,5.14,5.14,0,0,0,1.25-.81,5.89,5.89,0,0,0,1.09-1.29Z"
                style={{ fill: fill }}
              />
              <path
                d="M51.72,15.35a6.57,6.57,0,0,1-1.11,1.27,6.52,6.52,0,0,1-1.51,1,8.42,8.42,0,0,1-1.84.69,8.31,8.31,0,0,1-2.09.25,9.43,9.43,0,0,1-3.66-.7A8.84,8.84,0,0,1,36.65,13,9.68,9.68,0,0,1,36,9.3a9.59,9.59,0,0,1,.7-3.72,8.76,8.76,0,0,1,1.94-2.94A8.88,8.88,0,0,1,41.51.7,9.43,9.43,0,0,1,45.17,0a9,9,0,0,1,3.3.65A6.62,6.62,0,0,1,51.3,2.8L49.72,4A4.12,4.12,0,0,0,49,3.12a5,5,0,0,0-1.08-.74,6.39,6.39,0,0,0-1.32-.53,5.15,5.15,0,0,0-1.4-.2,7.14,7.14,0,0,0-3.05.63A6.74,6.74,0,0,0,39.84,4,7.2,7.2,0,0,0,38.4,6.39a8.72,8.72,0,0,0,0,5.82,7.2,7.2,0,0,0,1.44,2.44,6.9,6.9,0,0,0,2.28,1.68,7.29,7.29,0,0,0,3.05.62,8.07,8.07,0,0,0,1.39-.12,6.06,6.06,0,0,0,1.35-.43,5.14,5.14,0,0,0,1.25-.81,5.89,5.89,0,0,0,1.09-1.29Z"
                style={{ fill: fill }}
              />
              <path
                d="M72,9.3A9.68,9.68,0,0,1,71.3,13a8.84,8.84,0,0,1-4.86,4.87,9.94,9.94,0,0,1-7.33,0A8.84,8.84,0,0,1,54.25,13a9.68,9.68,0,0,1-.7-3.73,9.59,9.59,0,0,1,.7-3.72,8.76,8.76,0,0,1,1.94-2.94A8.88,8.88,0,0,1,59.11.7a9.94,9.94,0,0,1,7.33,0,8.88,8.88,0,0,1,2.92,1.94A8.76,8.76,0,0,1,71.3,5.58,9.59,9.59,0,0,1,72,9.3Zm-2,0a8.56,8.56,0,0,0-.5-2.91A7.2,7.2,0,0,0,68.11,4a6.78,6.78,0,0,0-2.29-1.67,7.7,7.7,0,0,0-6.1,0A6.74,6.74,0,0,0,57.44,4,7.2,7.2,0,0,0,56,6.39a8.72,8.72,0,0,0,0,5.82,7.2,7.2,0,0,0,1.44,2.44,6.9,6.9,0,0,0,2.28,1.68,7.81,7.81,0,0,0,6.1,0,6.94,6.94,0,0,0,2.29-1.68,7.2,7.2,0,0,0,1.44-2.44A8.56,8.56,0,0,0,70.05,9.3Z"
                style={{ fill: fill }}
              />
              <path
                d="M88.25,11.55A11.71,11.71,0,0,1,88,13.89a6.48,6.48,0,0,1-1,2.29,5.53,5.53,0,0,1-2,1.73,8,8,0,0,1-6.49,0,5.55,5.55,0,0,1-2-1.73,6.48,6.48,0,0,1-1-2.29,11.71,11.71,0,0,1-.26-2.34V.45h1.8v10.9a8.25,8.25,0,0,0,.25,2.19A5.62,5.62,0,0,0,78,15.08a3.65,3.65,0,0,0,.93,1,4.69,4.69,0,0,0,1,.56,3.86,3.86,0,0,0,1,.27,6.72,6.72,0,0,0,.82.06,7,7,0,0,0,.82-.06,3.93,3.93,0,0,0,1-.27,4.69,4.69,0,0,0,1-.56,3.81,3.81,0,0,0,.93-1,5.62,5.62,0,0,0,.66-1.54,8.25,8.25,0,0,0,.25-2.19V.45h1.8Z"
                style={{ fill: fill }}
              />
              <path
                d="M105.55,15.45h0V.45h1.8v17.7h-2.25L94.6,3h0V18.15h-1.8V.45H95Z"
                style={{ fill: fill }}
              />
              <path
                d="M117.87,18.15h-1.8V2.1h-5.92V.45H123.8V2.1h-5.93Z"
                style={{ fill: fill }}
              />
              <path
                d="M124.55,18.15h-2.1l7.7-17.7h1.75l7.7,17.7h-2.1l-1.95-4.65H126.5Zm2.62-6.3h7.68L131,2.55Z"
                style={{ fill: fill }}
              />
              <path
                d="M142,.45h5.27a9.86,9.86,0,0,1,2.13.23,5.38,5.38,0,0,1,1.82.75A3.83,3.83,0,0,1,152.5,2.8,4.35,4.35,0,0,1,153,4.9a3.92,3.92,0,0,1-.81,2.49,4,4,0,0,1-2.31,1.39v.05a4.25,4.25,0,0,1,2.94,1.31,4.46,4.46,0,0,1,1.06,3.11,5.59,5.59,0,0,1-.21,1.25,4.36,4.36,0,0,1-.89,1.64,5.58,5.58,0,0,1-1.95,1.41,8.09,8.09,0,0,1-3.4.6H142Zm1.8,7.75h3.62A5.07,5.07,0,0,0,148.71,8a3.29,3.29,0,0,0,1.15-.54,3,3,0,0,0,.84-1A2.89,2.89,0,0,0,151,5.1a3.41,3.41,0,0,0-.11-.77,2.42,2.42,0,0,0-.5-1,3.18,3.18,0,0,0-1.14-.87,4.71,4.71,0,0,0-2-.36H143.8Zm0,8.3h3.45a8.05,8.05,0,0,0,1.82-.2,4.61,4.61,0,0,0,1.48-.61,3.17,3.17,0,0,0,1-1,3,3,0,0,0,.36-1.5,3.05,3.05,0,0,0-1.13-2.6,5.39,5.39,0,0,0-3.3-.85H143.8Z"
                style={{ fill: fill }}
              />
              <path
                d="M159.55,16.5h8.25v1.65H157.75V.45h1.8Z"
                style={{ fill: fill }}
              />
              <path
                d="M172.05,16.5h9.47v1.65H170.25V.45h11V2.1h-9.17v6h8.57V9.78h-8.57Z"
                style={{ fill: fill }}
              />
            </g>
            <g style={{ isolation: 'isolate' }}>
              <path
                d="M2.51,28.45H7.79a9.84,9.84,0,0,1,2.12.23,5.43,5.43,0,0,1,1.83.75A3.8,3.8,0,0,1,13,30.8a4.23,4.23,0,0,1,.48,2.1,3.92,3.92,0,0,1-.81,2.49,4.06,4.06,0,0,1-2.32,1.39v0a4.25,4.25,0,0,1,2.94,1.31,4.41,4.41,0,0,1,1.06,3.11,5.59,5.59,0,0,1-.21,1.25,4.23,4.23,0,0,1-.89,1.64,5.58,5.58,0,0,1-1.95,1.41,8.06,8.06,0,0,1-3.4.6H2.51Zm1.8,7.75H7.94A5.18,5.18,0,0,0,9.23,36a3.47,3.47,0,0,0,1.15-.54,2.86,2.86,0,0,0,.83-1,2.89,2.89,0,0,0,.33-1.44,4.11,4.11,0,0,0-.11-.77,2.69,2.69,0,0,0-.5-1,3.18,3.18,0,0,0-1.14-.87,4.76,4.76,0,0,0-2-.36H4.31Zm0,8.3H7.76a8.21,8.21,0,0,0,1.83-.2,4.55,4.55,0,0,0,1.47-.61,3.07,3.07,0,0,0,1-1,3,3,0,0,0,.36-1.5,3,3,0,0,0-1.12-2.6A5.39,5.39,0,0,0,8,37.7H4.31Z"
                style={{ fill: fill }}
              />
              <path
                d="M35.51,37.3a9.68,9.68,0,0,1-.7,3.73A8.8,8.8,0,0,1,32.88,44,9,9,0,0,1,30,45.9a9.94,9.94,0,0,1-7.33,0A8.88,8.88,0,0,1,19.7,44,9,9,0,0,1,17.76,41a10.26,10.26,0,0,1,0-7.45,8.82,8.82,0,0,1,4.86-4.88,9.94,9.94,0,0,1,7.33,0,9,9,0,0,1,2.93,1.94,8.74,8.74,0,0,1,1.93,2.94A9.59,9.59,0,0,1,35.51,37.3Zm-1.95,0a8.32,8.32,0,0,0-.5-2.91A7.2,7.2,0,0,0,31.62,32a6.74,6.74,0,0,0-2.28-1.67,7.7,7.7,0,0,0-6.1,0A6.78,6.78,0,0,0,21,32a7.37,7.37,0,0,0-1.44,2.44,8.72,8.72,0,0,0,0,5.82A7.37,7.37,0,0,0,21,42.65a6.94,6.94,0,0,0,2.29,1.68,7.81,7.81,0,0,0,6.1,0,6.9,6.9,0,0,0,2.28-1.68,7.2,7.2,0,0,0,1.44-2.44A8.32,8.32,0,0,0,33.56,37.3Z"
                style={{ fill: fill }}
              />
              <path
                d="M56.36,37.3a9.68,9.68,0,0,1-.7,3.73A8.8,8.8,0,0,1,53.73,44,9,9,0,0,1,50.8,45.9a9.92,9.92,0,0,1-7.32,0A8.82,8.82,0,0,1,38.61,41a10.26,10.26,0,0,1,0-7.45,8.8,8.8,0,0,1,4.87-4.88,9.92,9.92,0,0,1,7.32,0,9,9,0,0,1,2.93,1.94,8.74,8.74,0,0,1,1.93,2.94A9.59,9.59,0,0,1,56.36,37.3Zm-2,0a8.32,8.32,0,0,0-.5-2.91A7.34,7.34,0,0,0,52.48,32a6.88,6.88,0,0,0-2.29-1.67,7.7,7.7,0,0,0-6.1,0A6.78,6.78,0,0,0,41.8,32a7.37,7.37,0,0,0-1.44,2.44,8.72,8.72,0,0,0,0,5.82,7.37,7.37,0,0,0,1.44,2.44,6.94,6.94,0,0,0,2.29,1.68,7.81,7.81,0,0,0,6.1,0,7,7,0,0,0,2.29-1.68,7.34,7.34,0,0,0,1.43-2.44A8.32,8.32,0,0,0,54.41,37.3Z"
                style={{ fill: fill }}
              />
              <path
                d="M61.76,36.12H62l8.33-7.67h2.57l-8.92,8,9.42,9.67H70.76L62.06,37h-.3v9.12H60V28.45h1.8Z"
                style={{ fill: fill }}
              />
              <path
                d="M77.51,36.12h.25l8.33-7.67h2.57l-8.92,8,9.42,9.67H86.51L77.81,37h-.3v9.12h-1.8V28.45h1.8Z"
                style={{ fill: fill }}
              />
              <path
                d="M93.26,44.5h9.48v1.65H91.46V28.45h11V30.1H93.26v6h8.58v1.66H93.26Z"
                style={{ fill: fill }}
              />
              <path
                d="M108.09,44.5h9.47v1.65H106.29V28.45h11V30.1h-9.17v6h8.57v1.66h-8.57Z"
                style={{ fill: fill }}
              />
              <path
                d="M121.11,28.45h5.15a7,7,0,0,1,4.26,1.17,4.16,4.16,0,0,1,1.57,3.56,4.17,4.17,0,0,1-1.57,3.56,6.89,6.89,0,0,1-4.26,1.19h-3.35v8.22h-1.8Zm1.8,7.83h2.9a7.48,7.48,0,0,0,2-.25,3.82,3.82,0,0,0,1.34-.67,2.42,2.42,0,0,0,.72-1,3.26,3.26,0,0,0,.23-1.21,3.15,3.15,0,0,0-.23-1.19,2.48,2.48,0,0,0-.72-1,3.65,3.65,0,0,0-1.34-.66,7.51,7.51,0,0,0-2-.24h-2.9Z"
                style={{ fill: fill }}
              />
              <path d="M137.26,46.15h-1.8V28.45h1.8Z" style={{ fill: fill }} />
              <path
                d="M154.86,43.45h0v-15h1.8v17.7h-2.25L143.91,31h0V46.15h-1.8V28.45h2.25Z"
                style={{ fill: fill }}
              />
              <path
                d="M174.59,31.7a6.71,6.71,0,0,0-2.15-1.5,7.8,7.8,0,0,0-5.95.08A6.78,6.78,0,0,0,164.2,32a7.2,7.2,0,0,0-1.44,2.44,8.72,8.72,0,0,0,0,5.82,7.2,7.2,0,0,0,1.44,2.44,6.94,6.94,0,0,0,2.29,1.68,7.26,7.26,0,0,0,3,.62,9.93,9.93,0,0,0,2.63-.34,9.28,9.28,0,0,0,2.27-.91V37.78h-4.23V36.12h6V44.8A13.13,13.13,0,0,1,173,46.14a13.53,13.53,0,0,1-3.41.46,9.45,9.45,0,0,1-3.67-.7A8.84,8.84,0,0,1,161,41a10.26,10.26,0,0,1,0-7.45A8.76,8.76,0,0,1,163,30.64a8.88,8.88,0,0,1,2.92-1.94,9.45,9.45,0,0,1,3.67-.7,10.33,10.33,0,0,1,3.66.59,7.79,7.79,0,0,1,2.74,1.79Z"
                style={{ fill: fill }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
