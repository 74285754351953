/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { FunctionComponent } from 'react'
import { Box, Flex } from 'theme-ui'
import { css } from '@emotion/core'
import { mq } from '../../theme'

import qbLogo from '../../../static/img/quickbooks-logo.png'
import xeroLogo from '../../../static/img/xero-logo.svg'
import sageLogo from '../../../static/img/sage-logo.png'
import aatLogo from '../../../static/img/aat-logo.png'

const AccountingLogos: FunctionComponent = () => {
  return (
    <Box bg="blue.0" py="5">
      <Box variant="container" sx={{ maxWidth: '1000px' }}>
        <Flex
          sx={{
            alignItems: 'center',
            flexWrap: ['wrap', 'nowrap'],
          }}
        >
          <Box sx={{ width: ['50%', 'initial'], minWidth: ['10%', '33%'] }}>
            <img
              css={css`
                display: block;
                width: 227px;
                margin: 0 auto;

                ${mq.sm} {
                  margin-left: 0;
                }
                ${mq.lg} {
                  width: 327px;
                }
              `}
              src={qbLogo}
              alt="QuickBooks Logo"
            />
          </Box>
          <Box sx={{ width: ['50%', 'initial'], minWidth: ['10%', '33%'] }}>
            <img
              css={css`
                display: block;
                width: 73px;
                height: 73px;
                margin: 0 auto;
              `}
              src={xeroLogo}
              alt="Xero Logo"
            />
          </Box>
          <Box
            sx={{
              width: ['100%', 'initial'],
              minWidth: ['10%', '33%'],
              mt: [5, 0],
            }}
          >
            <img
              css={css`
                display: block;
                width: 98px;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 5px;

                ${mq.sm} {
                  margin-right: initial;
                }
              `}
              src={aatLogo}
              alt="AAT Logo"
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default AccountingLogos
